<template>
  <v-app>
    <v-container class="my-6 pa-0" fluid>
      <v-card class="py-0">
        <div class="card-header-padding pb-0">
          <v-row class="mb-0">
            <v-col lg="2" sm="4" md="4" class="pb-0">
              <div>
                <h5 class="font-weight-bold text-h5 text-typo mb-0">
                  Үнэлгээ 2 -
                  <span
                    v-if="selectedProgram"
                    @click="_print2"
                    class="blue--text"
                    >{{ selectedProgram["STUDENT_GROUP_NAME"] }}
                  </span>
                </h5>
                <p class="text-typo">
                  Нийт xичээлийн тоо:
                  <span class="blue--text" v-if="filteredLessons">{{
                    filteredLessons.length
                  }}</span>
                </p>

                <v-progress-linear
                  v-if="pullingCourseLoading || isSavingToEsis"
                  color="red"
                  height="6"
                  indeterminate
                ></v-progress-linear>
                <v-btn
                  v-else
                  small
                  color="green"
                  class="white--text mt-n2 mb-2"
                  @click="getCourseDatas()"
                  >Хөтөлбөрийн мэдээлэл харах</v-btn
                >
              </div>
            </v-col>
            <!-- <v-col cols="auto">
              <v-btn
                elevation="0"
                class="bg-gradient-danger"
                @click="saveAllLessonsToESIS()"
                dark
                >Хичээлүүд ESIS-рүү хадгалах</v-btn
              >
            </v-col> -->
            <!-- <v-col cols="auto">
              <v-btn
                v-if="userData.role == 'superadmin'"
                elevation="0"
                class="bg-gradient-warning"
                @click="sendAllNull()"
                dark
                >Бүх сонгоныг засуулах
              </v-btn>
            </v-col> -->
            <!-- <v-col cols="auto">
              <v-btn
                v-if="userData.role == 'superadmin'"
                elevation="0"
                class="bg-gradient-info"
                @click="handleTeacherAndClassGroupAnalytics(true)"
                dark
                >Хувь засуулах
              </v-btn>
            </v-col> -->
            <v-spacer></v-spacer>
            <!-- <v-col cols="auto">
              <v-btn
                elevation="0"
                class="bg-gradient-danger"
                @click="saveAllLessonScoresToESIS()"
                dark
                >Дүн ESIS-рүү хадгалах</v-btn
              >
            </v-col> -->
          </v-row>
        </div>

        <small
          v-if="
            selectedProgram &&
            selectedProgram.AVG_CONTACT_HOURS_USED_FOR_ELECTIVE
          "
          @click="updateClassAVG()"
          style="cursor: pointer"
          class="ml-6"
          >(Сонгон) Долоо хоногт орох дундаж цаг:
          {{ selectedProgram.AVG_CONTACT_HOURS_USED_FOR_ELECTIVE }}</small
        >
        <div class="card-header-padding pt-0">
          <v-progress-linear
            v-if="loading || filteredLessons.length == 0"
            color="red"
            height="6"
            indeterminate
          ></v-progress-linear>
          <v-data-table
            class="scoreTables"
            v-if="lessons"
            hide-default-footer
            hide-default-header
            :items-per-page="-1"
            :items="filteredLessons"
          >
            <template slot="header">
              <tr style="font-size: 13px">
                <th class="text-start font-weight-normal">No</th>
                <th class="text-start font-weight-normal">Ээлжит хичээл</th>
                <th class="text-start font-weight-normal">Код</th>
                <th class="text-start font-weight-normal">
                  ESIS-т хичээл үүссэн
                </th>
                <th class="text-start font-weight-normal">Багш</th>
                <th class="text-start font-weight-normal">Xэлбэр</th>
                <th class="text-start font-weight-normal">Төрөл</th>
                <th class="text-start font-weight-normal">С.Т</th>
                <th class="pl-0">
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      class="text-start font-weight-normal"
                      style="
                        border-bottom: 2px solid #dddddd;
                        padding-left: 5px;
                      "
                      >Баталгаажсан</v-col
                    >
                    <v-col
                      class="text-center font-weight-normal"
                      cols="6"
                      style="border-right: 2px solid #dddddd; padding-left: 5px"
                      >Хувь</v-col
                    >
                    <v-col
                      class="text-center font-weight-normal"
                      cols="6"
                      style="padding-left: 5px"
                      >Тоо</v-col
                    >
                  </v-row>
                </th>
                <th class="text-center font-weight-normal">Хянагдаж байгаа</th>
                <th class="text-center font-weight-normal">Цуцлагдсан</th>
                <th class="text-center font-weight-normal">Бүртгэсэн</th>
                <th class="text-center font-weight-normal">
                  Үнэлгээний дундаж
                </th>
                <th>Үйлдэл</th>
                <th
                  v-if="
                    selectedProgram &&
                    selectedProgram.AVG_CONTACT_HOURS_USED_FOR_ELECTIVE
                  "
                >
                  7 хоногийн цаг
                </th>
                <!-- <th style="background-color: #e8f5e9" class="green--text">
                  Жилийн эцсийн дүнгээр
                </th>
                <th>ESIS харах</th> -->
                <!-- <th>ESIS-т хадгаласан</th> -->
              </tr>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td @click="_print(item)">{{ item.index }}</td>
                <td
                  class="text-start px-1"
                  style="white-space: nowrap; width: 30%"
                >
                  {{ item.courseInfo.COURSE_NAME }}

                  <!-- {{ item.courseInfo.SUBJECT_AREA_ID }} -->
                </td>
                <td class="text-uppercase grey--text">
                  {{ item.id.substring(0, 5) }}
                </td>
                <td class="text-center">
                  <v-progress-linear
                    v-if="item.lessonSavingLoading == true"
                    indeterminate
                    color="red"
                  ></v-progress-linear>
                  <!-- @click="getLessonData(item)" -->
                  <v-btn
                    @click="$swal.fire('Энэ хичээл ESIS-т үүссэн байна!')"
                    icon
                    v-else-if="item.savedToEsis"
                    x-small
                    color="green"
                  >
                    <v-icon>mdi-check-circle</v-icon>
                  </v-btn>

                  <v-btn
                    :title="item.lessonSavingErrorMessage"
                    x-small
                    v-else
                    @click="createEeljitLessonToEsis(item)"
                    icon
                    color="red"
                  >
                    <v-icon>mdi-reload-alert</v-icon>
                  </v-btn>
                </td>
                <td class="px-1">
                  <template>
                    <small
                      class="blue--text"
                      v-for="(teacher, teacherIndex) in getTeachers(item)"
                      :key="teacherIndex"
                      ><span v-if="teacherIndex > 0">, </span
                      >{{ teacher.teacherDisplayName }}</small
                    >
                  </template>
                </td>
                <td
                  v-if="item.courseInfo.ENROLLMENT_CATEGORY == 'ELECTIVE'"
                  class="px-1"
                >
                  <small class="px-1 red--text"> Сонгон</small>
                </td>
                <td v-else><small>Заавал</small></td>
                <td style="width: 5%">
                  <small
                    class="red--text"
                    v-if="item.esisLessonType.esisLessonTypeId == 2"
                    >Дэд бүлэг</small
                  >
                  <small v-else-if="item.esisLessonType.esisLessonTypeId == 3"
                    >Xолимог</small
                  >
                  <small v-else>Үндсэн</small>
                </td>
                <td
                  style="background-color: #ffebee"
                  v-if="
                    !item[
                      'year' +
                        selectedHalfYearId +
                        'UnelgeeScores-' +
                        selectedProgram.id
                    ] ||
                    item[
                      'year' +
                        selectedHalfYearId +
                        'UnelgeeScores-' +
                        selectedProgram.id
                    ].numberStudentsOfUnelgee < 1
                  "
                  class="text-center blue--text px-2"
                >
                  -
                </td>
                <td
                  v-else-if="
                    item[
                      'year' +
                        selectedHalfYearId +
                        'UnelgeeScores-' +
                        selectedProgram.id
                    ].numberStudentsOfUnelgee >= 1
                  "
                  class="text-center blue--text px-2"
                >
                  {{
                    item[
                      "year" +
                        selectedHalfYearId +
                        "UnelgeeScores-" +
                        selectedProgram.id
                    ].numberStudentsOfUnelgee
                  }}
                </td>
                <td v-else class="text-center">-</td>
                <td class="pl-0">
                  <v-row no-gutters style="height: 100% !important">
                    <v-col
                      cols="6"
                      class="pt-1 text-center"
                      style="border-right: 2px solid #dddddd; padding-left: 5px"
                    >
                      <span
                        style="color: green"
                        v-if="_getConfirmationProcent(item) >= 100"
                        color="green"
                        >100%</span
                      >
                      <span v-else-if="_getConfirmationProcent(item) == 0">
                        0%</span
                      >
                      <span v-else>
                        {{ _getConfirmationProcent(item).toFixed(1) }}%</span
                      >
                    </v-col>

                    <v-col
                      cols="6"
                      class="pt-1 text-center"
                      style="padding-left: 5px; color: green"
                    >
                      {{
                        item[
                          "year" +
                            selectedHalfYearId +
                            "UnelgeeScores-" +
                            selectedProgram.id
                        ] &&
                        item[
                          "year" +
                            selectedHalfYearId +
                            "UnelgeeScores-" +
                            selectedProgram.id
                        ].numberStudentsOfUnelgee &&
                        getAnalystics(item, 1) >
                          item[
                            "year" +
                              selectedHalfYearId +
                              "UnelgeeScores-" +
                              selectedProgram.id
                          ].numberStudentsOfUnelgee
                          ? item[
                              "year" +
                                selectedHalfYearId +
                                "UnelgeeScores-" +
                                selectedProgram.id
                            ].numberStudentsOfUnelgee
                          : getAnalystics(item, 1)
                      }}</v-col
                    >
                  </v-row>
                </td>
                <td style="color: #db9943" class="text-center">
                  {{ getAnalystics(item, 2) }}
                </td>
                <td style="color: red" class="text-center">
                  {{ getAnalystics(item, 3) }}
                </td>
                <td style="color: purple" class="text-center">
                  {{ getAnalystics(item, 4) }}
                </td>
                <td class="text-center">
                  {{ getAnalystics(item, 5) }}
                </td>
                <td>
                  <v-btn
                    v-if="_getConfirmationProcent(item) >= 100"
                    dark
                    elevation="0"
                    x-small
                    class="bg-gradient-success text-capitalize mx-2"
                    @click="_doUnelgee(item)"
                  >
                    Бүгд баталгаажсан
                  </v-btn>
                  <v-btn
                    v-else
                    dark
                    elevation="0"
                    x-small
                    class="bg-gradient-danger text-capitalize mx-2"
                    @click="_doUnelgee(item)"
                  >
                    баталгаaжуулах
                  </v-btn>
                </td>
                <td>
                  <v-menu
                    transition="slide-y-transition"
                    offset-y
                    offset-x
                    min-width="150"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        :ripple="false"
                        class="text-secondary ms-auto mt-3"
                        v-bind="attrs"
                        v-on="on"
                        small
                      >
                        <v-icon size="16">fas fa-ellipsis-v</v-icon>
                      </v-btn>
                    </template>

                    <v-list class="py-4">
                      <v-list-item
                        class="list-item-hover-active"
                        @click.stop="_deleteLesson(item)"
                      >
                        <v-list-item-content class="pa-0">
                          <v-list-item-title
                            class="ls-0 text-body font-weight-600 mb-0"
                          >
                            <v-btn
                              v-if="
                                item[
                                  'savedAllScoresToEsis-' +
                                    selectedHalfYear.yearId
                                ]
                              "
                              dark
                              elevation="0"
                              x-small
                              class="bg-gradient-danger text-capitalize mx-2"
                              @click.stop="prepareUnelgeeToEsis(item, true)"
                            >
                              Дүн ESIS-ээс устгах
                            </v-btn>
                            <v-btn
                              v-else
                              elevation="0"
                              x-small
                              class="mx-2"
                              @click.stop="prepareUnelgeeToEsis(item, false)"
                            >
                              ESIS-рүү хадгалах
                            </v-btn>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item
                        class="list-item-hover-active"
                        @click.stop="_deleteLesson(item)"
                      >
                        <v-list-item-content class="pa-0">
                          <v-list-item-title
                            class="ls-0 text-body font-weight-600 mb-0"
                          >
                            <v-btn class="red mx-2" x-small dark
                              >Xичээл устгаxxxx</v-btn
                            >
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <hr class="horizontal dark" />
                    </v-list>
                  </v-menu>
                </td>
                <!-- <td>
                  <small
                    v-if="
                      item['savedAllScoresToEsis-' + selectedHalfYear.yearId]
                    "
                    class="green--text"
                    >ESIS-т хадгалагдсан</small
                  >

                  <p
                    v-if="
                      item['savedAllScoresToEsisAt-' + selectedHalfYear.yearId]
                    "
                    class="mb-n1 mt-n2 blue--text"
                    style="font-size: 12px"
                  >
                    {{
                      item["savedAllScoresToEsisAt-" + selectedHalfYear.yearId]
                        | formatDate2
                    }}
                  </p>
                </td> -->
                <!-- <td
                  class="text-center"
                  v-if="
                    selectedProgram &&
                    selectedProgram.AVG_CONTACT_HOURS_USED_FOR_ELECTIVE
                  "
                >
                  {{ item.AVG_CONTACT_HOURS }}
                </td>
                <td style="white-space: nowrap">
                  <p
                    class="mb-n2 blue--text"
                    style="font-size: 12px"
                    v-if="
                      gradingMethods[item.GRADING_METHOD_USED_FOR_FINAL_GRADE]
                    "
                  >
                    {{
                      gradingMethods[item.GRADING_METHOD_USED_FOR_FINAL_GRADE]
                        .name
                    }}
                  </p>
                  <v-progress-linear
                    v-if="item.scoresSavingLoading == true"
                    indeterminate
                    color="red"
                  ></v-progress-linear>

                  <small
                    v-else-if="
                      item.finalGradeSavedToEsis &&
                      item['year' + selectedHalfYearId + 'UnelgeeScores'] &&
                      Number(getAnalystics(item, 1)) ==
                        Number(
                          item['year' + selectedHalfYearId + 'UnelgeeScores']
                            .numberStudentsOfUnelgee
                        )
                    "
                    class="green--text"
                    >ESIS-д хадгалагдсан</small
                  >

                  <p
                    v-if="
                      item.students &&
                      item.unelgee2Scores &&
                      !item.scoresSavingLoading &&
                      item.finalGradeSavedToEsisAt
                    "
                    class="mb-n1 mt-n2 blue--text"
                    style="font-size: 12px"
                  >
                    {{ item.finalGradeSavedToEsisAt | formatDate2 }}
                  </p>
                </td>
                <td>
                  <v-progress-linear
                    v-if="item.scoresShowingLoading == true"
                    indeterminate
                    color="red"
                  ></v-progress-linear>
                  <v-btn
                    elevation="0"
                    v-else-if="item.finalGradeSavedToEsis"
                    x-small
                    text
                    class="green--text"
                    @click="showingEsisSavedScores(item)"
                  >
                    Дүн харах
                  </v-btn>
                </td>
                <td v-if="item.courseInfo.ENROLLMENT_CATEGORY == 'ELECTIVE'">
                  <v-progress-linear
                    v-if="item.nullSendingLoading == true"
                    indeterminate
                    color="red"
                  ></v-progress-linear>
                  <v-btn
                    v-else-if="
                      item.students &&
                      item.finalGradeSavedToEsis &&
                      Number(getAnalystics(item, 1)) ==
                        Number(
                          item['year' + selectedHalfYearId + 'UnelgeeScores']
                            .numberStudentsOfUnelgee
                        ) &&
                      item.students.length != selectedProgram.students.length
                    "
                    elevation="0"
                    x-small
                    dark
                    :color="
                      item.finalEsisGradeExtraStudentsFixed ? 'green' : 'red'
                    "
                    class="text-capitalize"
                    @click="sendNull2(item)"
                  >
                    {{
                      item.finalEsisGradeExtraStudentsFixed
                        ? "Сонгон зассан"
                        : "Сонгон засуулах"
                    }}
                  </v-btn>
                </td>
                 -->
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-card>
    </v-container>
    <v-dialog
      v-model="unelgeeDialog"
      height="500"
      width="1400"
      v-if="currentSelectedLesson && renderComponent"
      persistent
    >
      <v-card class="py-4 scoreTables">
        <div class="card-header-padding pb-0">
          <v-row class="pt-4">
            <v-col lg="6" md="6" sm="6" cols="6" class="mt-1">
              <h5 class="font-weight-bold text-h5 text-typo mb-0 ml-4">
                Үнэлгээ 2 - {{ userData.school.currentYear }}
                <span
                  class="blue--text ml-4"
                  v-if="currentSelectedLesson"
                  @click="_print3"
                >
                  {{ currentSelectedLesson.courseInfo.COURSE_NAME }}/
                  <span
                    v-if="
                      currentSelectedLesson.classGroups &&
                      currentSelectedLesson.classGroups.length > 0
                    "
                  >
                    {{
                      currentSelectedLesson.classGroups[0].classGroupFullName
                    }}
                  </span>
                </span>
              </h5>
            </v-col>
            <!-- <v-col lg="3" md="3" sm="3" cols="3" class="mt-n5">
              <v-select
                v-model="selectedGradingMethod"
                label="Бүлэг сэдвийн үнэлгээ (Үнэлгээ-I)"
                item-text="name"
                item-value="value"
                :items="gradingMethods"
              >
              </v-select>
            </v-col>
            <v-col class="justify-self-end" cols="auto">
              <v-switch
                disabled
                class="ml-2 my-0 py-0"
                color="red"
                v-model="simpleCalculationModel"
                :label="
                  simpleCalculationModel
                    ? 'Энгийн (' + simpleCalculationModel + ')'
                    : 'Ахисан (' + simpleCalculationModel + ')'
                "
              ></v-switch>
            </v-col> -->
          </v-row>
          <v-divider></v-divider>
        </div>
        <ScoreInputUnelgee25DialogManager
          class="pt-0"
          v-if="
            currentSelectedLesson &&
            currentSelectedLesson.students &&
            currentSelectedLesson.unelgee2Scores &&
            currentSelectedLesson.unelgee1Scores
          "
          v-bind="$attrs"
          :lesson="currentSelectedLesson"
          :selectedGradingMethod="selectedGradingMethod"
        >
        </ScoreInputUnelgee25DialogManager>
        <v-card-text
          class="mx-10 py-10 mt-4 px-4 text-center"
          style="width: 80%; overflow: hidden"
          v-if="savingUnelgee"
        >
          <p style="display: block !important" class="red--text">
            Дүн хадгалж байна. Түр хүлээнэ үү!
            <span v-if="isSavingToEsis"
              >ESIS-рүү хадгалж байгаа тул удаж магадгүй. Энэхүү хуудсыг дуустал
              нь xааxгүй байна уу!</span
            >
          </p>
          <v-progress-linear
            color="red"
            height="6"
            indeterminate
          ></v-progress-linear>
        </v-card-text>
        <v-card-actions class="mx-6 py-10 mt-4" v-else>
          <div
            v-if="
              currentSelectedLesson &&
              currentSelectedLesson.unelgee2Scores &&
              externExamsExists
            "
            class="red--text text-start"
          >
            <!-- 1230 -->
            <!-- <span v-if="checkExternGrades()">
              ТТШ шалгалт дүнгүй ({{
                currentSelectedLesson.unelgee2Scores.filter(
                  (score) =>
                    (score.register || score.register_no) &&
                    (!score.eScore || typeof score.eScore.eScore != "number")
                ).length
              }}):
              {{
                currentSelectedLesson.unelgee2Scores
                  .filter(
                    (score) =>
                      ((score.register || score.register_no) &&
                        !score.eScore) ||
                      typeof score.eScore.eScore != "number"
                  )
                  .map((score) => score.LAST_NAME[0] + "." + score.FIRST_NAME)
                  .join(", ")
              }}
            </span> -->
            <br />
            <span v-if="checkingStudRegister()">
              Регистргүй ({{
                currentSelectedLesson.students.filter(
                  (score) => !score.register && !score.register_no
                ).length
              }}):
              {{
                currentSelectedLesson.students
                  .filter((score) => !score.register && !score.register_no)
                  .map((score) => score.LAST_NAME[0] + "." + score.FIRST_NAME)
                  .join(", ")
              }}
            </span>
          </div>
          <v-spacer></v-spacer>
          <v-btn color="grey" class="white--text" @click="_closeUnelgeeDialog">
            Цуцлах
          </v-btn>
          <v-btn
            @click="_saveUnelgee2(false)"
            class="bg-gradient-success white--text ml-2 mr-3 text-capitalize"
          >
            Хадгалах
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showGradesFromEsisDialog"
      v-if="lessonStudentScores && renderComponent"
      width="1000"
    >
      <v-card class="py-4 scoreTables">
        <div class="card-header-padding pb-0">
          <v-row class="pt-4">
            <v-col>
              <h5 class="font-weight-bold text-h5 text-typo mb-0 ml-4">
                ESIS-т бичсэн жилийн эцсийн дүн (Үнэлгээ 2) -
                {{ userData.school.currentYear }}
                <span
                  class="blue--text ml-4"
                  v-if="currentSelectedLesson"
                  @click="_print3"
                >
                  {{ currentSelectedLesson.courseInfo.COURSE_NAME }}/
                  <span
                    v-if="
                      currentSelectedLesson.classGroups &&
                      currentSelectedLesson.classGroups.length > 0
                    "
                  >
                    {{
                      currentSelectedLesson.classGroups[0].classGroupFullName
                    }}
                  </span>
                </span>
              </h5>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </div>

        <v-simple-table class="mx-10 mt-5">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">No.</th>
                <th class="text-left">Нэр</th>
                <th class="text-left">Бүлэг</th>
                <th class="text-left">Эцсийн дүн</th>
                <th class="text-left">Түвшин</th>
                <th class="text-left">Хадгаласан огноо</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(student, sIndex) in lessonStudentScores"
                :key="'studentttt' + sIndex"
              >
                <td style="width: 1%">{{ sIndex + 1 }}</td>
                <td>
                  {{ student.FIRST_NAME }}
                  <span style="color: #9e9e9e">, {{ student.LAST_NAME }}</span>
                </td>
                <td class="blue--text">
                  {{ student.STUDENT_GROUP_NAME }}
                </td>
                <td>{{ student.GRADE_MARK }}</td>
                <td>{{ student.GRADE_CODE }}</td>
                <td>
                  {{
                    currentSelectedLesson.finalGradeSavedToEsisAt | formatDate
                  }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card></v-dialog
    >

    <v-dialog
      v-model="showLessonEsisDataDialog"
      v-if="selectedEsisLesson"
      width="1000"
    >
      <v-card class="pa-4">
        <v-data-table
          :items="selectedEsisLesson.byTeachers"
          hide-default-footer
        >
          <template slot="header">
            <tr style="font-size: 13px">
              <th class="text-start font-weight-normal">No</th>
              <th class="text-start font-weight-normal">Хичээлийн нэр</th>
              <th class="text-start font-weight-normal">Багшийн нэр</th>
              <th class="text-start font-weight-normal">Хөтөлбөрийн нэр</th>
            </tr>
          </template>
          <template v-slot:item="{ item }">
            <tr v-if="selectedEsisLesson.savedToEsisData">
              <td>
                {{ item.index }}
              </td>
              <td>
                {{ selectedEsisLesson.courseInfo.COURSE_NAME }}
              </td>
              <td>{{ item.teacherDisplayName }}</td>
              <td v-if="selectedEsisLesson.savedToEsisData.programPlan">
                {{
                  selectedEsisLesson.savedToEsisData.programPlan
                    .PROGRAM_PLAN_NAME
                }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showCoursesEsisDataDialog"
      v-if="coursesData"
      width="1000"
    >
      <v-card class="pa-4">
        <v-card-title
          class="px-0"
          v-if="
            coursesData.programPlan && coursesData.programPlan.PROGRAM_PLAN_NAME
          "
          ><strong>Хөтөлбөрийн нэр: </strong>
          {{ coursesData.programPlan.PROGRAM_PLAN_NAME }},
          {{ coursesData.programPlan.id }}</v-card-title
        >
        <v-data-table
          :items-per-page="-1"
          :items="coursesData.courses"
          hide-default-footer
          style="border-top: 1px solid #dddddd !important"
        >
          <template slot="header">
            <tr style="font-size: 13px">
              <th class="text-start font-weight-normal" style="width: 2%">
                No
              </th>
              <th class="text-start font-weight-normal">Хичээлийн нэр</th>
              <th class="text-start font-weight-normal">7 хоногийн цаг</th>
              <th class="text-start font-weight-normal">Багц цаг</th>
              <th class="text-start font-weight-normal">Дүнгийн схемийн нэр</th>
              <th class="text-start font-weight-normal">Дүнгийн схемийн код</th>
            </tr>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>
                {{ item.index }}
              </td>
              <td>
                {{ item.COURSE_NAME }}
              </td>
              <td>
                {{ item.AVG_CONTACT_HOURS }}
              </td>
              <td>
                {{ item.COURSE_CONTACT_HOURS }}
              </td>
              <td>
                {{ item.GRADING_SCHEME_NAME }}
              </td>
              <td>
                {{ item.GRADING_SCHEME_ID }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import ScoreInputUnelgee25DialogManager from "./ScoreInputUnelgee25DialogManager";
const fb = require("@/firebaseConfig.js");
import axios from "axios";
import moment from "moment";

export default {
  components: {
    ScoreInputUnelgee25DialogManager,
  },
  data: () => ({
    setupped: false,
    esisToken: null,
    halfYearDate: null,
    halfYear2Date: null,
    currentSelectedSemester: 2,
    coursesAdditional: null,
    school: null,
    showMyClassLesson: true,
    students: null,
    selectedProgram: null,
    teachers: null,
    selectedType: { name: "Заавал судлаx", value: 1, kValue: "MANDATORY" },
    lessons: null,
    loading: true,
    currentSelectedLesson: null,
    renderComponent: true,
    unelgeeDialog: false,
    savingUnelgee: false,
    simpleCalculationModel: true,
    allScores: null,
    selectedGradingMethod: 1,
    gradingMethods: [
      {
        name: "Дундаж дүнгээр",
        value: 0,
      },
      {
        name: "Хамгийн өндөр дүнгээр",
        value: 1,
      },
      // {
      //   name: "Сүүлийн шалгалтын дүнгээр",
      //   value: 2,
      // },
    ],
    unelgee1Scores: null,
    loadingInverval: null,
    selectedHalfYear: null,
    preText: null,
    savingGradeLoading: false,
    showGradesFromEsisDialog: false,
    lessonStudentScores: null,
    selectedEsisLesson: null,
    showLessonEsisDataDialog: null,
    coursesData: null,
    pullingCourseLoading: false,
    showCoursesEsisDataDialog: false,
    isSavingToEsis: false,
    lessonNumberSetupped: false,
    externExamsExists: null,
  }),

  props: {
    zClassGroupRefPath: {
      type: String,
      required: true,
    },
    selectedHalfYearId: {
      required: true,
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),

    filteredLessons() {
      var list = [];
      // if (this.teacherLessons) list = [...this.teacherLessons];

      if (this.lessons) {
        this.lessons.forEach((lesson) => {
          if (lesson.esisLessonType.esisLessonTypeId == 3) {
            for (var program of lesson.classGroups) {
              if (program.classGroupRef.path == this.selectedProgram.ref.path) {
                var tmp = Object.assign({}, lesson);
                tmp.classGroups = [program];
                tmp.classGroupRefs = [program.classGroupRef];
                tmp.classGroupIds = [program.classGroupRef.id];
                tmp["year" + this.selectedHalfYear.yearId + "UnelgeeScores"] =
                  lesson[
                    "year" +
                      this.selectedHalfYear.yearId +
                      "UnelgeeScores-" +
                      program.classGroupRef.id
                  ]
                    ? lesson[
                        "year" +
                          this.selectedHalfYear.yearId +
                          "UnelgeeScores-" +
                          program.classGroupRef.id
                      ]
                    : {};
                list.push(tmp);
              }
            }
          } else {
            list.push(lesson);
          }
        });
      }

      list.sort(
        (a, b) => a.courseInfo.PROGRAM_STAGE_ID - b.courseInfo.PROGRAM_STAGE_ID
      );
      list.forEach((item, idx) => {
        item.index = idx + 1;
      });
      return list;
    },
  },
  created() {
    this.halfYearDate = new Date(
      this.$store.state.calendarButez2[0].months[
        this.$store.state.calendarButez2[0].months.length - 1
      ].days[
        this.$store.state.calendarButez2[0].months[
          this.$store.state.calendarButez2[0].months.length - 1
        ].days.length - 1
      ].year +
        "-" +
        this.$store.state.calendarButez2[0].months[
          this.$store.state.calendarButez2[0].months.length - 1
        ].name +
        "-" +
        this.$store.state.calendarButez2[0].months[
          this.$store.state.calendarButez2[0].months.length - 1
        ].days[
          this.$store.state.calendarButez2[0].months[
            this.$store.state.calendarButez2[0].months.length - 1
          ].days.length - 1
        ].day
    );
    // this.halfYear2Date = new Date(
    //   this.$store.state.calendarButez2[2].months[0].days[0].year +
    //     "-" +
    //     this.$store.state.calendarButez2[2].months[
    //       this.$store.state.calendarButez2[2].months.length - 1
    //     ].name +
    //     "-" +
    //     this.$store.state.calendarButez2[2].months[
    //       this.$store.state.calendarButez2[2].months.length - 1
    //     ].days[
    //       this.$store.state.calendarButez2[2].months[
    //         this.$store.state.calendarButez2[2].months.length - 1
    //       ].days.length - 1
    //     ].day
    // );
    this.preText =
      this.userData.school.currentYear +
      "-" +
      this.userData.school.currentYear +
      1;
    this.selectedHalfYear = {
      yearId: Number(this.selectedHalfYearId),
      name: this.preText + " xичээлийн жил, 1-р xагас жил",
    };
    if (!this.school) {
      this.school = this.userData.school;
    }
    if (
      typeof this.userData.school.GRADING_METHOD_USED_FOR_FINAL_GRADE ==
      "number"
    ) {
      this.selectedGradingMethod =
        this.userData.school.GRADING_METHOD_USED_FOR_FINAL_GRADE;
    }
    fb.db.doc(this.zClassGroupRefPath).onSnapshot(async (doc) => {
      let prog = doc.data();
      prog.ref = doc.ref;
      prog.id = doc.id;
      await this.userData.school.ref
        .collection("students-" + this.userData.school.currentYear)
        .where("STUDENT_GROUP_ID", "==", Number(prog.STUDENT_GROUP_ID))
        .get()
        .then((docs) => {
          prog.students = [];
          docs.forEach((doc) => {
            let student = doc.data();
            student.ref = doc.ref;
            student.id = doc.id;
            if (this.selectedHalfYear.yearId == 1) {
              if (
                !student.ACTION_DATE ||
                new Date(student.ACTION_DATE) <= this.halfYearDate
              ) {
                if (!student.moved) {
                  prog.students.push(student);
                }
              }
            } else {
              if (
                !student.ACTION_DATE ||
                new Date(student.ACTION_DATE) > this.halfYearDate
              ) {
                if (!student.moved) {
                  prog.students.push(student);
                }
              }
            }
            // if (!student.moved) prog.students.push(student);
          });
        });
      this.selectedProgram = prog;

      if (!this.lessons) this.readLessons();
    });
  },
  methods: {
    _deleteLesson(lesson) {
      console.log(this.userData.firstName, this.userData.ref.path);
      console.log(this.userData.role);
      console.log(lesson.ref.path);
      if (this.userData.role == "schoolmanager") {
        lesson.ref.get().then((doc) => {
          var ll = doc.data();
          ll.ref = doc.ref;
          this.$swal({
            title: ll.courseInfo.COURSE_NAME + " Xичээлийг устгаx уу?",
            text:
              "Багш: " +
              ll.byTeachers
                .map((tt) => tt.teacherDisplayName)
                .filter((value, index, self) => self.indexOf(value) === index) +
              ", Бүлгүүд: " +
              ll.classGroups.map((cg) => cg.classGroupFullName).join(", "),
            type: "warning",
            showCancelButton: true,
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
            confirmButtonText: "Тийм",
            cancelButtonText: "Үгүй",
            reverseButtons: true,
          }).then((result) => {
            if (result.isConfirmed) {
              lesson.ref
                .update({
                  deleted: true,
                  deletedByManager: true,
                  deletedByName: this.userData.firstName
                    ? this.userData.firstName
                    : null,
                  deletedByEmail: this.userData.email
                    ? this.userData.email
                    : null,
                  deletedByRef: this.userData.ref,
                })
                .then(() => {
                  console.log(lesson.ref.path);
                });
            }
          });
        });
      } else {
        this.$swal.fire("Танд устгаx эрx байxгүй!");
      }

      // console.log(
      //   lesson.ref.path,
      //   "year" +
      //     this.selectedHalfYearId +
      //     "UnelgeeScores-" +
      //     this.selectedProgram.id
      // );

      // console.log(
      //   lesson[
      //     "year" +
      //       this.selectedHalfYearId +
      //       "UnelgeeScores-" +
      //       this.selectedProgram.id
      //   ]
      // );
    },
    checkingStudRegister() {
      if (
        this.currentSelectedLesson.students.filter(
          (score) => !score.register && !score.register_no
        ).length > 0
      ) {
        return true;
      } else {
        return false;
      }
    },
    checkExternGrades() {
      if (
        this.currentSelectedLesson.unelgee2Scores.filter(
          (score) =>
            (score.register || score.register_no) &&
            (!score.eScore || typeof score.eScore.eScore != "number")
        ).length > 0
      ) {
        return true;
      } else {
        return false;
      }
    },
    _getShortNameCodeForExternExam(lesson) {
      //1229
      var input = lesson.courseInfo.SUBJECT_AREA_ID;
      switch (input) {
        case 2:
          return "mx";
        case 3:
          return "mx";
        case 4:
          return "mx";

        case 5:
          return "mat";
        case 9:
          return "x"; //himi

        case 11:
          return "bio";

        case 12:
          return "gz";

        case 10:
          return "f"; //physics

        case 13:
          return "tuuh"; //tuuh, niigem

        case 18:
          return "tex"; //tuuh, niigem
        case 20:
          return "xj";
        case 24:
          return "ax";

        case 25:
          return "ox";
        case 194:
          return "tuuh"; // Mongoliin tuuh
        case 195:
          return "tuuh"; // niigem

        case 196:
          return "mt";

        case 233:
          return "tex"; //ddzz
        default:
          return null;
      }
    },
    //25107
    _isAllowedForExternExams(acdemicLevel, subjectAreaId) {
      if (acdemicLevel == "6" && subjectAreaId == 3) return false; //uran zohiol
      else if (acdemicLevel == "6" && subjectAreaId == 4)
        return false; //undesnii bichig
      else if (acdemicLevel == "8" && subjectAreaId == 3)
        return false; //uran zohiol
      else if (acdemicLevel == "8" && subjectAreaId == 4)
        return false; //undesnii bichig
      else if (acdemicLevel == "9" && subjectAreaId == 4)
        return false; //undesnii bichig
      else if (acdemicLevel == "10" && subjectAreaId == 4)
        return false; //undesnii bichig
      else if (acdemicLevel == "11" && subjectAreaId == 3)
        return false; //uran zohiol
      else if (acdemicLevel == "11" && subjectAreaId == 4)
        return false; //undesnii bichig
      else return true;
    },
    getTeachers(item) {
      var list = [];
      item.byTeachers.forEach((teacher) => {
        if (list.find((t) => t.PERSON_ID == teacher.PERSON_ID) == undefined) {
          list.push(teacher);
        }
      });
      return list;
    },
    async _isWithExternExam(lesson) {
      //25106
      var classGroup = lesson.classGroups[0];
      var allowed = this._isAllowedForExternExams(
        classGroup.ACADEMIC_LEVEL,
        lesson.courseInfo.SUBJECT_AREA_ID
      );
      if (lesson.courseInfo.ENROLLMENT_CATEGORY == "MANDATORY" && allowed) {
        // console.log(this._getShortNameCodeForExternExam(lesson));
        // var classs = lesson.classGroups[0];
        if (this.userData.school.id == "bIZDwo6lAhHPQLBfdfrX" && classGroup) {
          var qq = await this.userData.school.ref
            .collection("externexams")
            .doc(this.userData.school.currentYear.toString())
            .collection(
              this._getShortNameCodeForExternExam(lesson) +
                "-" +
                classGroup.ACADEMIC_LEVEL
            )
            .limit(1)
            .get();
          this.externExamsExists = !qq.empty;
        } else return false;
      } else return false;
    },
    getAnalystics(lesson, mode) {
      if (
        lesson[
          "year" +
            this.selectedHalfYearId +
            "UnelgeeScores-" +
            this.selectedProgram.id
        ]
      )
        if (mode == 1) {
          return lesson[
            "year" +
              this.selectedHalfYearId +
              "UnelgeeScores-" +
              this.selectedProgram.id
          ].approvedNumber
            ? lesson[
                "year" +
                  this.selectedHalfYearId +
                  "UnelgeeScores-" +
                  this.selectedProgram.id
              ].approvedNumber
            : "-";
        } else if (mode == 2) {
          return lesson[
            "year" +
              this.selectedHalfYearId +
              "UnelgeeScores-" +
              this.selectedProgram.id
          ].inProgressNumber
            ? lesson[
                "year" +
                  this.selectedHalfYearId +
                  "UnelgeeScores-" +
                  this.selectedProgram.id
              ].inProgressNumber
            : "-";
        } else if (mode == 3) {
          return lesson[
            "year" +
              this.selectedHalfYearId +
              "UnelgeeScores-" +
              this.selectedProgram.id
          ].rejectedNumber
            ? lesson[
                "year" +
                  this.selectedHalfYearId +
                  "UnelgeeScores-" +
                  this.selectedProgram.id
              ].rejectedNumber
            : "-";
        } else if (mode == 4) {
          return lesson[
            "year" +
              this.selectedHalfYearId +
              "UnelgeeScores-" +
              this.selectedProgram.id
          ].savedNumber
            ? lesson[
                "year" +
                  this.selectedHalfYearId +
                  "UnelgeeScores-" +
                  this.selectedProgram.id
              ].savedNumber
            : "-";
        } else if (mode == 5) {
          return lesson[
            "year" +
              this.selectedHalfYearId +
              "UnelgeeScores-" +
              this.selectedProgram.id
          ].scoreAverage
            ? lesson[
                "year" +
                  this.selectedHalfYearId +
                  "UnelgeeScores-" +
                  this.selectedProgram.id
              ].scoreAverage
            : "-";
        } else return "-";
    },
    _getConfirmationProcent(item) {
      var xxx = this.getAnalystics(item, 1);
      var yyy =
        item[
          "year" +
            this.selectedHalfYearId +
            "UnelgeeScores-" +
            this.selectedProgram.id
        ] &&
        item[
          "year" +
            this.selectedHalfYearId +
            "UnelgeeScores-" +
            this.selectedProgram.id
        ].numberStudentsOfUnelgee
          ? item[
              "year" +
                this.selectedHalfYearId +
                "UnelgeeScores-" +
                this.selectedProgram.id
            ].numberStudentsOfUnelgee
          : null;
      if (typeof xxx === "number" && yyy) return (xxx / yyy) * 100;
      return 0;
    },
    _print3() {
      console.log(this.currentSelectedLesson);
    },
    calScoreLevel(item) {
      item.scoreFinal = null;
      item.scoreFinal =
        item.score != null &&
        item.score != undefined &&
        typeof item.score == "number"
          ? item.score
          : 0;

      let gradePoints;
      let gradeStr;
      let tmpScore = item.scoreFinal;
      if (tmpScore >= 90) {
        gradePoints = 8;
        gradeStr = "VIII";
      } else if (tmpScore >= 80) {
        gradePoints = 7;
        gradeStr = "VII";
      } else if (tmpScore >= 70) {
        gradePoints = 6;
        gradeStr = "VI";
      } else if (tmpScore >= 60) {
        gradePoints = 5;
        gradeStr = "V";
      } else if (tmpScore >= 50) {
        gradePoints = 4;
        gradeStr = "IV";
      } else if (tmpScore >= 40) {
        gradePoints = 3;
        gradeStr = "III";
      } else if (tmpScore >= 30) {
        gradePoints = 2;
        gradeStr = "II";
      } else if (tmpScore >= 0 && typeof tmpScore == "number") {
        gradePoints = 1;
        gradeStr = "I";
      } else if (
        tmpScore == null ||
        tmpScore == undefined ||
        typeof tmpScore != "number"
      ) {
        gradePoints = null;
        gradeStr = null;
      }

      item.scoreLevelNumber = gradePoints;
      item.scoreLevelString = gradeStr;

      this.$forceUpdate();
    },
    async _doUnelgee(lesson) {
      this.currentSelectedLesson = lesson;
      this._isWithExternExam(lesson);
      if (this.currentSelectedLesson.esisLessonType.esisLessonTypeId > 1) {
        this.currentSelectedLesson.students =
          await this.currentSelectedLesson.ref
            // .collection("students-" + this.userData.school.currentYear) DO NOT DO CURRENTYEAR!!!
            .collection(
              "students-" + this.$store.state.currentSchoolHalfYear.yearId
            )
            .get()
            .then((docs) => {
              //TODO students-variable needed
              var list = [];
              docs.forEach((doc) => {
                let student = doc.data();
                student.ref = doc.ref;
                student.id = doc.id;

                var foundStudent = this.selectedProgram.students.find(
                  (s) => Number(s.PERSON_ID) == Number(student.PERSON_ID)
                );
                if (foundStudent) {
                  student.register = foundStudent.register;
                  student.register_no = foundStudent.register_no;
                  list.push(student);
                }
              });
              return list;
            });
      } else {
        this.currentSelectedLesson.students = this.selectedProgram.students;
      }

      var unelgee1ScoresCounter = 0;
      await this.userData.school.ref
        .collection("unelgee2-" + this.userData.school.currentYear + "-scores")
        .doc(String(this.currentSelectedLesson.id))
        .collection("scores")
        .where("sentToManager", "==", true)
        .orderBy("createdAt", "asc")
        .get()
        .then((docs) => {
          var batch = fb.db.batch();
          this.currentSelectedLesson.unelgee2Scores = [];
          docs.forEach(async (doc) => {
            let score = doc.data();
            score.ref = doc.ref;
            score.id = doc.id;

            if (
              this.currentSelectedLesson.courseInfo.ENROLLMENT_CATEGORY ==
                "ELECTIVE" &&
              this.userData.school.id == "bIZDwo6lAhHPQLBfdfrX"
            ) {
              if (typeof score.score1 == "number") {
                score.score = score.score1;
                this.calScoreLevel(score);

                batch.update(score.ref, {
                  score: score.score,
                  scoreFinal: score.scoreFinal,
                  scoreLevelNumber: score.scoreLevelNumber,
                  scoreLevelString: score.scoreLevelString,
                });
              }
            }

            if (score.unelgee1 && score.unelgee1.length > 0) {
              unelgee1ScoresCounter =
                unelgee1ScoresCounter + score.unelgee1.length; //1227 number to check if manager needs to pull unelgee1scores again
            }
            this.currentSelectedLesson.unelgee2Scores.push(score);
          });
          batch.commit();
          setTimeout(() => {
            this._saveUnelgee2(true);
          }, 1000);
        });

      this.currentSelectedLesson.students.sort((a, b) =>
        a["FIRST_NAME"].localeCompare(b["FIRST_NAME"])
      );
      this.currentSelectedLesson.loading2 = true;
      await this.userData.school.ref
        .collection("unelgee1-" + this.userData.school.currentYear)
        .doc(String(this.currentSelectedLesson.courseInfo.COURSE_ID))
        .collection("unelgee1-" + this.userData.school.currentYear + "-scores")
        .orderBy("createdAt", "asc")
        .onSnapshot((querySnapshot) => {
          this.currentSelectedLesson.unelgee1Scores = [];
          var counter = 0;
          // console.log(unelgee1ScoresCounter, querySnapshot.size);
          if (
            querySnapshot.size == 0 ||
            unelgee1ScoresCounter == querySnapshot.size //1227 if equal unelgee1 is up to date, if not equal unelgee1 is outdated needs updating
          ) {
            this.saveStudentNumberToLesson();
            this.unelgeeDialog = true;
          } else {
            querySnapshot.forEach(async (doc) => {
              let item = doc.data();
              item.id = doc.id;
              item.ref = doc.ref;
              await item.examRef.get().then((doc) => {
                if (doc.exists && doc.data().deleted != true) {
                  item.examDate = new Date(doc.data().examDate);
                  item.deleted = false;
                } else {
                  item.deleted = true;
                }
              });
              if (item.deleted == false) {
                this.currentSelectedLesson.unelgee1Scores.push(item);
              }
              counter++;
              if (counter == querySnapshot.size) {
                this.forceRerender();
                this.saveStudentNumberToLesson();
                this.unelgeeDialog = true;
              }
            });
          }
        });
    },
    saveStudentNumberToLesson() {
      var tmp = {};
      this.currentSelectedLesson.students.forEach((stud) => {
        if (
          !tmp[
            "year" +
              this.selectedHalfYear.yearId +
              "UnelgeeScores-" +
              stud.STUDENT_GROUP_ID
          ]
        ) {
          tmp[
            "year" +
              this.selectedHalfYear.yearId +
              "UnelgeeScores-" +
              stud.STUDENT_GROUP_ID
          ] = {
            numberStudentsOfUnelgee: 0,
          };
        }
        tmp[
          "year" +
            this.selectedHalfYear.yearId +
            "UnelgeeScores-" +
            stud.STUDENT_GROUP_ID
        ].numberStudentsOfUnelgee++;
      });
      this.currentSelectedLesson.ref.set(tmp, { merge: true });
    },
    saveUnelgeeToLesson(statisticData, batch) {
      statisticData[
        "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
      ].numberStudentsOfUnelgee = this.currentSelectedLesson.students
        ? this.currentSelectedLesson.students.length
        : 0;
      var tmp = {};
      tmp[
        "year" +
          this.selectedHalfYear.yearId +
          "UnelgeeScores-" +
          this.currentSelectedLesson.classGroupRefs[0].id
      ] =
        statisticData["year" + this.selectedHalfYear.yearId + "UnelgeeScores"];
      batch.set(this.currentSelectedLesson.ref, tmp, {
        merge: true,
      });
    },

    saveUnelgeeToProgram(statisticData, batch) {
      var tmp = {};

      tmp["year" + this.selectedHalfYear.yearId + "UnelgeeScores"] = {};

      tmp["year" + this.selectedHalfYear.yearId + "UnelgeeScores"][
        this.currentSelectedLesson.id
      ] =
        statisticData["year" + this.selectedHalfYear.yearId + "UnelgeeScores"];

      tmp["year" + this.selectedHalfYear.yearId + "UnelgeeScores"][
        this.currentSelectedLesson.id
      ].doneByTeacherRef = this.currentSelectedLesson.byTeachers[0].teacherRef;

      tmp["year" + this.selectedHalfYear.yearId + "UnelgeeScores"][
        this.currentSelectedLesson.id
      ].doneByTeacherId = this.currentSelectedLesson.byTeachers[0].teacherId;

      tmp["year" + this.selectedHalfYear.yearId + "UnelgeeScores"][
        this.currentSelectedLesson.id
      ].numberStudentsOfUnelgee = this.currentSelectedLesson.students
        ? this.currentSelectedLesson.students.length
        : 0;
      console.log(tmp);
      batch.set(this.currentSelectedLesson.classGroupRefs[0], tmp, {
        merge: true,
      });
    },

    saveUnelgeeToTeacher(statisticData, batch) {
      var tmp = {};

      tmp[
        "year" +
          this.selectedHalfYear.yearId +
          "UnelgeeScores-" +
          this.userData.school.currentYear
      ] = {};

      tmp[
        "year" +
          this.selectedHalfYear.yearId +
          "UnelgeeScores-" +
          this.userData.school.currentYear
      ][this.currentSelectedLesson.id + "-" + this.selectedProgram.id] =
        statisticData["year" + this.selectedHalfYear.yearId + "UnelgeeScores"];

      tmp[
        "year" +
          this.selectedHalfYear.yearId +
          "UnelgeeScores-" +
          this.userData.school.currentYear
      ][
        this.currentSelectedLesson.id + "-" + this.selectedProgram.id
      ].numberStudentsOfUnelgee = this.currentSelectedLesson.students
        ? this.currentSelectedLesson.students.length
        : 0;

      batch.set(
        this.userData.school.ref
          .collection(
            "unelgee2-" + this.userData.school.currentYear + "-teachers"
          )
          .doc(String(this.currentSelectedLesson.byTeachers[0].teacherId)),
        tmp,
        { merge: true }
      );
    },
    async prepareUnelgeeToEsis(lesson, deleting) {
      this.currentSelectedLesson = lesson;
      if (this.currentSelectedLesson.esisLessonType.esisLessonTypeId > 1) {
        this.currentSelectedLesson.students =
          await this.currentSelectedLesson.ref
            // .collection("students-" + this.userData.school.currentYear) DO NOT DO CURRENTYEAR!!!
            .collection(
              "students-" + this.$store.state.currentSchoolHalfYear.yearId
            )
            .get()
            .then((docs) => {
              //TODO students-variable needed
              var list = [];
              docs.forEach((doc) => {
                let student = doc.data();
                student.ref = doc.ref;
                student.id = doc.id;

                var foundStudent = this.selectedProgram.students.find(
                  (s) => Number(s.PERSON_ID) == Number(student.PERSON_ID)
                );
                if (foundStudent) {
                  student.register = foundStudent.register;
                  student.register_no = foundStudent.register_no;
                  list.push(student);
                }
              });
              return list;
            });
      } else {
        this.currentSelectedLesson.students = this.selectedProgram.students;
      }

      await this.userData.school.ref
        .collection("unelgee2-" + this.userData.school.currentYear + "-scores")
        .doc(String(this.currentSelectedLesson.id))
        .collection("scores")
        .where("sentToManager", "==", true)
        .orderBy("createdAt", "asc")
        .get()
        .then((docs) => {
          this.currentSelectedLesson.unelgee2Scores = [];
          docs.forEach(async (doc) => {
            let score = doc.data();
            score.ref = doc.ref;
            score.id = doc.id;

            this.currentSelectedLesson.unelgee2Scores.push(score);
          });
        });

      var batch = fb.db.batch();
      console.log(this.currentSelectedLesson);
      this.isSavingToEsis = true;

      await this.saveUnelgeeToEsis(batch, deleting);

      this.isSavingToEsis = false;
    },
    async saveUnelgeeToEsis(batch, deleting) {
      var failed = false;

      if (!this.esisToken) {
        this.$swal.fire(
          "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу! Дүн ESIS-рүү хадгалаагүй!"
        );
        this.$forceUpdate();
        return false;
      }

      //checking if eeljitlesson exists
      const headers = {
        "Content-Type": "application/json",
      };

      var body = {
        school: {
          _esis_schoolInfo: {
            legalEntityId: this.userData.school._esis_schoolInfo.legalEntityId,
            academicYear: this.userData.school.currentYear,
          },
        },
        token: this.esisToken,
      };
      try {
        var response = await axios.post(
          "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/retrieveEeljitLessonsFromEsis",
          body,
          { headers: headers }
        );
      } catch (e) {
        //
      }

      console.log(response.data);

      if (response && response.data.SUCCESS_CODE === 200) {
        var eeljitLessons = response.data.RESULT;
      }

      var foundEeljitLesson = eeljitLessons.find(
        (lesson) =>
          lesson.CLASS_ID_LMS ==
          this.currentSelectedLesson.savedToEsisData.CLASS_ID_LMS
      );

      if (!foundEeljitLesson) {
        this.$swal.fire(
          "ESIS алдаа гарлаа. Ээлжит хичээл үсээгүй байна! Дүн ESIS-рүү хадгалаагүй!"
        );
        this.$forceUpdate();
        return false;
      }

      console.log(foundEeljitLesson);

      //getting data ready to save to esis
      var studentList = [];
      for (var student of this.currentSelectedLesson.students) {
        var foundScore = this.currentSelectedLesson.unelgee2Scores.find(
          (score) => Number(score.PERSON_ID) == Number(student.PERSON_ID)
        );
        if (
          foundScore &&
          foundScore.approved &&
          typeof foundScore.scoreFinal === "number"
        ) {
          studentList.push({
            PERSON_ID: Number(foundScore.PERSON_ID),
            GRADE_MARK: Number(foundScore.scoreFinal),
          });
        } else {
          failed = true;
        }
      }

      if (studentList.length != this.currentSelectedLesson.students.length)
        failed = true;

      if (failed) {
        this.$swal.fire(
          `Дүн дутуу байна! Дүн ESIS-рүү хадгалаагүй! ${studentList.length}/${this.currentSelectedLesson.students.length}`
        );
        this.$forceUpdate();
        return false;
      }

      //saving to esis
      body = {
        termId: Number(this.selectedHalfYear.yearId),
        studentList: studentList,
        classId: Number(foundEeljitLesson.CLASS_ID),
        token: this.esisToken,
      };
      console.log(body, "body");

      try {
        var url = deleting
          ? "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/deleteUnelgee2ToEeljitLessonEsis"
          : "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/saveUnelgee2ToEeljitLessonEsis";
        if (deleting) body.username = this.userData.email;
        response = await axios.post(
          url, //25218
          body,
          { headers: headers }
        );
        console.log(response);
        if (response.data.res.SUCCESS_CODE === 200) {
          if (deleting) {
            var tmp = {};
            tmp["savedAllScoresToEsis-" + this.selectedHalfYear.yearId] = false;
            tmp["savedAllScoresToEsisAt-" + this.selectedHalfYear.yearId] =
              new Date();
            batch.update(this.currentSelectedLesson.ref, tmp);

            this.$swal.fire(
              `${response.data.res.RESPONSE_MESSAGE}. Дүн ESIS-ээс устгасан.`
            );

            this.currentSelectedLesson.unelgee2Scores.forEach((score) => {
              batch.update(score.ref, {
                savedToEsis: false,
                savedToEsisAt: new Date(),
              });
            });
          } else {
            tmp = {};
            tmp["savedAllScoresToEsis-" + this.selectedHalfYear.yearId] = true;
            tmp["savedAllScoresToEsisAt-" + this.selectedHalfYear.yearId] =
              new Date();
            batch.update(this.currentSelectedLesson.ref, tmp);

            this.$swal.fire(
              `${response.data.res.RESPONSE_MESSAGE}. Дүн ESIS-рүү хадгалагдсан`
            );

            this.currentSelectedLesson.unelgee2Scores.forEach((score) => {
              batch.update(score.ref, {
                savedToEsis: true,
                savedToEsisAt: new Date(),
              });
            });
          }
        } else {
          if (deleting) {
            tmp = {};
            tmp[
              "deletingAllScoresToEsisErrorMessage-" +
                this.selectedHalfYear.yearId
            ] =
              response.data.res.SUCCESS_CODE +
              " | " +
              response.data.res.RESPONSE_MESSAGE;
          } else {
            tmp = {};
            tmp[
              "savingAllScoresToEsisErrorMessage-" +
                this.selectedHalfYear.yearId
            ] =
              response.data.res.SUCCESS_CODE +
              " | " +
              response.data.res.RESPONSE_MESSAGE;
          }

          batch.update(this.currentSelectedLesson.ref, tmp);
          this.$swal.fire(
            `ESIS алдаа гарлаа. ${response.data.res.RESPONSE_MESSAGE}! Дүн ESIS-рүү хадгалаагүй!`
          );
        }
      } catch (e) {
        console.error(e.message);
        this.$swal.fire(
          "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу! Дүн ESIS-рүү хадгалаагүй!"
        );
      }
    },

    async handleEsisToken() {
      var tokenDoc = await fb.db
        .doc("/_schoolTokens/" + this.userData.school.id)
        .get()
        .then(async (doc) => {
          if (doc.exists) {
            return doc.data();
          } else {
            return null;
          }
        });

      const now = new Date();
      const yesterdayDate = new Date(now.getTime() - 24 * 60 * 60 * 1000);
      if (tokenDoc && tokenDoc.tokenTakenAt.toDate() > yesterdayDate) {
        this.esisToken = tokenDoc.token;
      } else {
        try {
          const response = await axios.post(
            "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/esislogin",
            {
              username: this.userData.school._esisUserName,
              password: this.userData.school._esisPword,
            }
          );

          if (response.status === 200 && response.data.token) {
            await fb.db
              .doc("/_schoolTokens/" + this.userData.school.id)
              .set(
                {
                  token: response.data.token,
                  tokenTakenAt: new Date(),
                  schoolName: this.userData.school.name,
                },
                { merge: true }
              )
              .then(() => {
                console.log("TokEN updated!!!!!");
                this.esisToken = response.data.token;
              });
          } else {
            console.error(response.data);
          }
        } catch (e) {
          console.error(e.message);
        }
      }
    },
    async createEeljitLessonToEsis(lesson) {
      if (!lesson.savedToEsis) {
        this.$forceUpdate();
        lesson.lessonSavingLoading = true;

        const body = {
          lesson: {
            id: lesson.id,
            courseInfo: {
              COURSE_ID: lesson.courseInfo.COURSE_ID,
              SUBJECT_AREA_ID: lesson.courseInfo.SUBJECT_AREA_ID,
              ENROLLMENT_CATEGORY: lesson.courseInfo.ENROLLMENT_CATEGORY,
            },
          },
          program: {
            STUDENT_GROUP_NAME: this.selectedProgram.STUDENT_GROUP_NAME,
            ACADEMIC_LEVEL: this.selectedProgram.ACADEMIC_LEVEL,
            STUDENT_GROUP_ID: this.selectedProgram.STUDENT_GROUP_ID,
            PROGRAM_OF_STUDY_ID: this.selectedProgram.PROGRAM_OF_STUDY_ID,
          },
          school: {
            legalEntityId: this.userData.school._esis_schoolInfo.legalEntityId,
            academicYear: this.userData.school.currentYear,
          },
          teacherId: lesson.byTeachers[0].teacherId,
          token: this.esisToken,
        };
        const headers = {
          "Content-Type": "application/json",
        };
        console.log(body);

        try {
          const response = await axios.post(
            "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/createEeljitLessonEsis",
            body,
            { headers: headers }
          );

          console.log(response);
          if (response.data.res.SUCCESS_CODE === 200) {
            lesson.ref.update({
              savedToEsis: true,
              savedToEsisAt: new Date(),
              savedToEsisData: response.data.req,
            });
          } else {
            lesson.ref.update({
              lessonSavingErrorMessage: response.data.res.RESPONSE_MESSAGE,
            });
            this.$forceUpdate();
            console.log(response.data);
          }
        } catch (e) {
          console.error(e.message);
        }

        lesson.lessonSavingLoading = false;
        this.$forceUpdate();
      }
    },
    async createEeljitLessonsToEsis() {
      await this.handleEsisToken();
      console.log(this.esisToken);
      if (this.esisToken) {
        for (var lesson of this.lessons) {
          console.log(lesson, "XX");
          await this.createEeljitLessonToEsis(lesson);
        }
      } else {
        console.error("no token");
      }
    },

    async _saveUnelgee2(savingOnlyStatistics) {
      var batch = fb.db.batch();
      if (!savingOnlyStatistics) this.savingUnelgee = true;
      var scoreLevelStrings = {
        _I: 0,
        _II: 0,
        _III: 0,
        _IV: 0,
        _V: 0,
        _VI: 0,
        _VII: 0,
        _VIII: 0,
      };
      var scoreLevelStrings2 = {
        passed: 0,
        failed: 0,
      };
      var allCompleted = true;

      for (var score of this.currentSelectedLesson.unelgee2Scores) {
        if (
          score.scoreFinal != undefined &&
          score.scoreFinal != null &&
          typeof score.scoreFinal == "number"
        ) {
          score["approvedAt"] = new Date();
          score["approvedByRef"] = this.userData.ref;
          score["approvedByEmail"] = this.userData.email;
          if (score.approved == false) {
            score["rejected"] = true;
          }

          // score.STUDENT_GROUP_ID = this.selectedProgram.STUDENT_GROUP_ID;
          // score.STUDENT_GROUP_NAME = this.selectedProgram.STUDENT_GROUP_NAME;
          // score.classGroupRef = this.selectedProgram.ref;

          batch.update(score.ref, score);

          if (score.approved == true) {
            if (
              this.currentSelectedLesson.courseInfo.GRADING_SCHEME_ID == 13 ||
              this.currentSelectedLesson.courseInfo.GRADING_SCHEME_ID == 14
            ) {
              if (score.scoreFinal > 0) {
                scoreLevelStrings2["passed"]++;
              } else {
                scoreLevelStrings2["failed"]++;
              }
            } else {
              if (score.scoreLevelNumber == 1) {
                scoreLevelStrings["_I"]++;
              } else if (score.scoreLevelNumber == 2) {
                scoreLevelStrings["_II"]++;
              } else if (score.scoreLevelNumber == 3) {
                scoreLevelStrings["_III"]++;
              } else if (score.scoreLevelNumber == 4) {
                scoreLevelStrings["_IV"]++;
              } else if (score.scoreLevelNumber == 5) {
                scoreLevelStrings["_V"]++;
              } else if (score.scoreLevelNumber == 6) {
                scoreLevelStrings["_VI"]++;
              } else if (score.scoreLevelNumber == 7) {
                scoreLevelStrings["_VII"]++;
              } else if (score.scoreLevelNumber == 8) {
                scoreLevelStrings["_VIII"]++;
              }
            }
          } else {
            allCompleted = false;
          }
        }
      }

      var statisticData = {};
      statisticData["year" + this.selectedHalfYear.yearId + "UnelgeeScores"] = {
        approvedNumber: 0,
        inProgressNumber: 0,
        rejectedNumber: 0,
        savedNumber: 0,
        scoreAverage: 0,
      };

      // go through students and finalizing the inProgress, savedNumber, approvedNumber, etc.
      for (var student of this.currentSelectedLesson.students) {
        var studentScore = this.currentSelectedLesson.unelgee2Scores.find(
          (studentScore) => studentScore.PERSON_ID == student.PERSON_ID
        );

        if (studentScore) {
          //if score is approved by manager, we add them
          if (studentScore.approved == true) {
            statisticData[
              "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
            ].approvedNumber++;
            if (
              this.currentSelectedLesson.courseInfo.GRADING_SCHEME_ID == 13 ||
              this.currentSelectedLesson.courseInfo.GRADING_SCHEME_ID == 14
            ) {
              if (studentScore.score > 0) {
                statisticData[
                  "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
                ].scoreAverage =
                  statisticData[
                    "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
                  ].scoreAverage + 100;
              } else {
                statisticData[
                  "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
                ].scoreAverage =
                  statisticData[
                    "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
                  ].scoreAverage + studentScore.score;
              }
            } else {
              //baga angias deesh dungeer toozdog bol
              statisticData[
                "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
              ].scoreAverage =
                statisticData[
                  "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
                ].scoreAverage + studentScore.score;
            }
          } else if (studentScore.approved == false) {
            statisticData[
              "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
            ].rejectedNumber++;
          } else if (
            (studentScore.approved == undefined ||
              studentScore.approved == null) &&
            studentScore.sentToManager == true
          ) {
            statisticData[
              "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
            ].inProgressNumber++;
          } else if (
            studentScore.sentToManager != true &&
            studentScore.score != null &&
            studentScore.score != undefined &&
            typeof studentScore.score == "number"
          ) {
            statisticData[
              "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
            ].savedNumber++;
          }
        }
      }

      statisticData[
        "year" + this.selectedHalfYear.yearId + "UnelgeeScores"
      ].scoreAverage = Number(
        (
          statisticData["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
            .scoreAverage /
          statisticData["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
            .approvedNumber
        ).toFixed(1)
      );

      statisticData["year" + this.selectedHalfYear.yearId + "UnelgeeScores"][
        "allUnelgee2ScoresConfirmed-" + this.selectedHalfYear.yearId
      ] = allCompleted;

      statisticData["year" + this.selectedHalfYear.yearId + "UnelgeeScores"][
        "allUnelgee2ScoresConfirmedAt-" + this.selectedHalfYear.yearId
      ] = new Date();

      this.saveUnelgeeToLesson(statisticData, batch);

      if (
        this.currentSelectedLesson.courseInfo.GRADING_SCHEME_ID == 13 ||
        this.currentSelectedLesson.courseInfo.GRADING_SCHEME_ID == 14
      ) {
        batch.set(
          this.userData.school.ref
            .collection(
              "unelgee2-" + this.userData.school.currentYear + "-scores"
            )
            .doc(
              this.selectedHalfYear.yearId > 1
                ? this.currentSelectedLesson.id +
                    "-" +
                    this.selectedHalfYear.yearId
                : this.currentSelectedLesson.id
            ),
          {
            _schoolId: this.userData.school.id,
            _ACADEMIC_LEVEL: this.selectedProgram.ACADEMIC_LEVEL,
            _completed100: allCompleted,
            _COURSE_ID: this.currentSelectedLesson.courseInfo.COURSE_ID,

            _SUBJECT_AREA_ID:
              this.currentSelectedLesson.courseInfo.SUBJECT_AREA_ID,
            _SUBJECT_AREA_NAME:
              this.currentSelectedLesson.courseInfo.SUBJECT_AREA_NAME,

            passed: scoreLevelStrings2["passed"],
            failed: scoreLevelStrings2["failed"],
          },
          { merge: true }
        );
      } else {
        batch.set(
          this.userData.school.ref
            .collection(
              "unelgee2-" + this.userData.school.currentYear + "-scores"
            )
            .doc(
              this.selectedHalfYear.yearId > 1
                ? this.currentSelectedLesson.id +
                    "-" +
                    this.selectedHalfYear.yearId
                : this.currentSelectedLesson.id
            ),
          {
            _schoolId: this.userData.school.id,
            _ACADEMIC_LEVEL: this.selectedProgram.ACADEMIC_LEVEL,
            _completed100: allCompleted,
            _COURSE_ID: this.currentSelectedLesson.courseInfo.COURSE_ID,

            _SUBJECT_AREA_ID:
              this.currentSelectedLesson.courseInfo.SUBJECT_AREA_ID,
            _SUBJECT_AREA_NAME:
              this.currentSelectedLesson.courseInfo.SUBJECT_AREA_NAME,

            _I: scoreLevelStrings["_I"],
            _II: scoreLevelStrings["_II"],
            _III: scoreLevelStrings["_III"],
            _IV: scoreLevelStrings["_IV"],
            _V: scoreLevelStrings["_V"],
            _VI: scoreLevelStrings["_VI"],
            _VII: scoreLevelStrings["_VII"],
            _VIII: scoreLevelStrings["_VIII"],
          },
          { merge: true }
        );
      }

      this.saveUnelgeeToProgram(statisticData, batch);
      this.saveUnelgeeToTeacher(statisticData, batch);

      if (!savingOnlyStatistics) {
        console.log(
          statisticData["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
            .approvedNumber,
          this.currentSelectedLesson.students.length
        );
        if (
          statisticData["year" + this.selectedHalfYear.yearId + "UnelgeeScores"]
            .approvedNumber == this.currentSelectedLesson.students.length
        ) {
          this.isSavingToEsis = true;
          this.$forceUpdate();
          try {
            await this.saveUnelgeeToEsis(batch); //25218
          } catch (e) {
            //
          }

          this.isSavingToEsis = false;
          this.$forceUpdate();
        }
      }

      batch.commit().then(async () => {
        if (allCompleted) {
          //callxxx
        }
        if (!savingOnlyStatistics) this.savingUnelgee = false;
        this.$forceUpdate();
        if (!savingOnlyStatistics) this._closeUnelgeeDialog();
      });
    },
    _closeUnelgeeDialog() {
      this.currentSelectedLesson.loading2 = false;
      this.unelgeeDialog = !this.unelgeeDialog;
    },
    _print2() {
      console.log(this.selectedProgram.ref.path);
    },
    updateClassAVG() {
      var AVG_HOURS = [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6];
      var AVG_HOURS_HTML = `
        <select id="select-input" class="swal2-select swal2-select-bordered">
      `;
      for (var hours of AVG_HOURS) {
        if (hours == this.selectedProgram.AVG_CONTACT_HOURS_USED_FOR_ELECTIVE) {
          AVG_HOURS_HTML =
            AVG_HOURS_HTML +
            `<option selected value="${hours}">${hours}</option>`;
        } else {
          AVG_HOURS_HTML =
            AVG_HOURS_HTML + `<option value="${hours}">${hours}</option>`;
        }
      }

      AVG_HOURS_HTML = AVG_HOURS_HTML + "</select>";

      this.$swal
        .fire({
          title: "Долоо хоногт орох дундаж ЦАГАА сонгоно уу!",
          text: "",
          html: AVG_HOURS_HTML,
          showCancelButton: true,
          confirmButtonText: "Xадгалаx",
          cancelButtonText: "Цуцлаx",
          reverseButtons: true,
          preConfirm: () => {
            const selectValue = document.getElementById("select-input").value;
            return selectValue;
          },
          customClass: {
            cancelButton: "swal2-cancel-button-custom",
            confirmButton: "swal2-ok-button-custom",
          },
        })
        .then((result) => {
          if (result.isConfirmed) {
            if (result.value) {
              this.selectedProgram.ref.update({
                AVG_CONTACT_HOURS_USED_FOR_ELECTIVE: Number(result.value),
              });
              this.selectedProgram.AVG_CONTACT_HOURS_USED_FOR_ELECTIVE = Number(
                result.value
              );
              this.$forceUpdate();
            }
          }
        });
    },
    // async showingEsisSavedScores(lesson) {
    //   this.currentSelectedLesson = lesson;
    //   lesson.scoresShowingLoading = true;
    //   this.$forceUpdate();
    //   let tries = 10;
    //   var token = false;
    //   while (token == false && tries > 0) {
    //     token = await axios
    //       .post(
    //         "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/esislogin",
    //         {
    //           username: this.userData.school._esisUserName,
    //           password: this.userData.school._esisPword,
    //         }
    //       )
    //       .then((res) => {
    //         console.log(res);
    //         if (res.status === 200) {
    //           return res.data.token;
    //         } else {
    //           return false;
    //         }
    //       });
    //     tries--;
    //   }

    //   if (!token) {
    //     this.$swal.fire(
    //       "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
    //     );
    //     lesson.scoresShowingLoading = false;
    //     return false;
    //   }

    //   const headers = {
    //     "Content-Type": "application/json",
    //   };
    //   if (!lesson.xProgramPlanId) {
    //     const headers = {
    //       "Content-Type": "application/json",
    //     };

    //     var studentBody = {
    //       program: {
    //         STUDENT_GROUP_ID: this.selectedProgram.STUDENT_GROUP_ID,
    //       },
    //       token: token,
    //     };

    //     try {
    //       var response2 = await axios
    //         .post(
    //           "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/retrieveStudentsFromEsis",
    //           studentBody,
    //           headers
    //         )
    //         .then((response) => {
    //           return response.data;
    //         });
    //     } catch {
    //       console.log("pulling students failed");
    //       lesson.scoresShowingLoading = false;
    //       this.$swal.fire(
    //         "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
    //       );
    //       return false;
    //     }

    //     var programPlanId = null;
    //     if (response2.status == true) {
    //       var notEqualProgramPlanId = false;
    //       response2.students.forEach((student) => {
    //         if (!programPlanId) {
    //           programPlanId = student.PROGRAM_PLAN_ID;
    //         } else {
    //           if (student.PROGRAM_PLAN_ID != programPlanId) {
    //             notEqualProgramPlanId = true;
    //           }
    //         }
    //       });

    //       if (notEqualProgramPlanId) {
    //         console.log("program plan id not same for all students");
    //         lesson.scoresShowingLoading = false;
    //         this.$swal.fire(
    //           "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
    //         );
    //         return false;
    //       }
    //       if (!programPlanId) {
    //         console.log("program plan id missing");
    //         lesson.scoresShowingLoading = false;
    //         this.$swal.fire(
    //           "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
    //         );
    //         return false;
    //       }
    //     }
    //     console.log(response2, programPlanId);
    //     console.log(this.selectedProgram);
    //     var courseBody = {
    //       program: {
    //         PROGRAM_OF_STUDY_ID: this.selectedProgram.PROGRAM_OF_STUDY_ID,
    //         PROGRAM_STAGE_ID: this.selectedProgram.PROGRAM_STAGE_ID,
    //       },
    //       programPlanId: programPlanId,
    //       token: token,
    //     };
    //     try {
    //       var response3 = await axios
    //         .post(
    //           "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/retrieveCoursesFromEsis",
    //           courseBody,
    //           headers
    //         )
    //         .then((response) => {
    //           return response.data;
    //         });
    //     } catch {
    //       console.log("pulling courses failed");
    //       lesson.scoresShowingLoading = false;
    //       this.$swal.fire(
    //         "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
    //       );
    //       return false;
    //     }
    //     console.log(response3);
    //     if (response3.status == false) {
    //       console.log("pulling courses failed, " + response3.reason);
    //       lesson.scoresShowingLoading = false;
    //       this.$swal.fire(
    //         "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
    //       );
    //       return false;
    //     }
    //     if (response3.status == true) {
    //       if (response3.courses && response3.courses.length > 0) {
    //         lesson.courseData = response3.courses.find(
    //           (course) => course.COURSE_ID == lesson.courseInfo.COURSE_ID
    //         );
    //         var coursePlan = await this.userData.school.ref
    //           .collection("schoolPrograms")
    //           .doc(String(this.selectedProgram.PROGRAM_OF_STUDY_ID))
    //           .collection("stages")
    //           .doc(String(this.selectedProgram.PROGRAM_STAGE_ID))
    //           .collection("plans")
    //           .doc(String(programPlanId))
    //           .get()
    //           .then((doc) => {
    //             if (doc.exists) {
    //               let coursePlan = doc.data();
    //               coursePlan.ref = doc.ref;
    //               coursePlan.id = doc.id;
    //               return coursePlan;
    //             } else {
    //               return {
    //                 PROGRAM_PLAN_NAME: "-",
    //               };
    //             }
    //           });
    //         if (!lesson.courseData) {
    //           console.log("course missing");
    //           lesson.scoresShowingLoading = false;
    //           this.$swal.fire(
    //             "ESIS алдаа гарлаа." +
    //               coursePlan.PROGRAM_PLAN_NAME +
    //               " дугаартай хөтөлбөр дээр энэ хичээл нэмэгдэггүй байх магагдлалтай. Та хичээлийн хөтөлбөрдөө байгааг шалгаад байхгүй бол оруулаад дахин оролдоорой."
    //           );
    //           return false;
    //         }
    //       }
    //     }
    //     lesson.ref.update({
    //       xProgramPlanId: lesson.courseData.PROGRAM_ELEMENT_ID,
    //     });
    //   }
    //   console.log(
    //     this.selectedProgram.STUDENT_GROUP_ID,
    //     lesson.xProgramPlanId,
    //     "xxx",
    //     token
    //   );
    //   try {
    //     await axios
    //       .post(
    //         "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getFinalGradesFromEsis",
    //         {
    //           token: token,
    //           studentGroupId: this.selectedProgram.STUDENT_GROUP_ID,
    //           programElementId: lesson.xProgramPlanId
    //             ? lesson.xProgramPlanId
    //             : lesson.courseData.PROGRAM_ELEMENT_ID,
    //         },
    //         headers
    //       )
    //       .then((res) => {
    //         console.log(res);
    //         if (res.status === 200 && res.data.response.SUCCESS_CODE === 200) {
    //           this.lessonStudentScores = [];
    //           res.data.response.RESULT.forEach((stud) => {
    //             stud.FIRST_NAME = this.currentSelectedLesson.students.find(
    //               (student) => student.PERSON_ID == stud.PERSON_ID
    //             ).FIRST_NAME;

    //             stud.LAST_NAME = this.currentSelectedLesson.students.find(
    //               (student) => student.PERSON_ID == stud.PERSON_ID
    //             ).LAST_NAME;

    //             stud.STUDENT_GROUP_NAME =
    //               this.currentSelectedLesson.students.find(
    //                 (student) => student.PERSON_ID == stud.PERSON_ID
    //               ).STUDENT_GROUP_NAME;
    //             this.lessonStudentScores.push(stud);
    //           });
    //           this.lessonStudentScores.sort((a, b) =>
    //             a["FIRST_NAME"].localeCompare(b["FIRST_NAME"])
    //           );
    //         } else {
    //           this.$swal.fire(
    //             "ESIS алдаа гарлаа. " +
    //               res.data.response.RESPONSE_MESSAGE +
    //               " .Та хүлээж байгаад дахин оролдоно уу!"
    //           );
    //         }
    //         lesson.scoresShowingLoading = false;
    //         this.showGradesFromEsisDialog = true;
    //         this.$forceUpdate();
    //         this.forceRerender();
    //       });
    //   } catch (e) {
    //     console.log(e);
    //     this.$swal.fire(
    //       "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
    //     );
    //     lesson.scoresShowingLoading = false;

    //     return false;
    //   }
    // },
    async getCourseDatas() {
      if (!this.pullingCourseLoading) {
        this.pullingCourseLoading = true;
        this.$forceUpdate();

        if (!this.esisToken) {
          this.pullingCourseLoading = false;
          this.$swal.fire(
            "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
          );
          this.$forceUpdate();
          return false;
        }

        const headers = {
          "Content-Type": "application/json",
        };

        var studentBody = {
          program: {
            STUDENT_GROUP_ID: this.selectedProgram.STUDENT_GROUP_ID,
          },
          token: this.esisToken,
        };

        try {
          var response = await axios
            .post(
              "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/retrieveStudentsFromEsis",
              studentBody,
              headers
            )
            .then((res) => {
              return res.data;
            });
        } catch {
          console.log("pulling students failed");
          this.pullingCourseLoading = false;
          this.$swal.fire(
            "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
          );
          this.$forceUpdate();
          return false;
        }
        console.log(response);

        var programPlanId = null;
        var PROGRAM_STAGE_ID = null;
        var PROGRAM_OF_STUDY_ID = null;
        if (response.status == true) {
          var notEqualProgramPlanId = false;
          response.students.forEach((student) => {
            PROGRAM_STAGE_ID = student.PROGRAM_STAGE_ID;
            PROGRAM_OF_STUDY_ID = student.PROGRAM_OF_STUDY_ID;
            if (!programPlanId) {
              programPlanId = student.PROGRAM_PLAN_ID;
            } else {
              if (student.PROGRAM_PLAN_ID != programPlanId) {
                notEqualProgramPlanId = true;
              }
            }
          });

          if (notEqualProgramPlanId) {
            console.log("program plan id not same for all students");
            this.pullingCourseLoading = false;
            this.$swal.fire("ESIS алдаа гарлаа. Сурагчдын хөтөлбөр зөрсөн!");
            this.$forceUpdate();
            return false;
          }
          if (!programPlanId) {
            console.log("program plan id missing");
            this.pullingCourseLoading = false;
            this.$swal.fire("ESIS алдаа гарлаа. Сурагчдын хөтөлбөр дутуу!");
            this.$forceUpdate();
            return false;
          }
        }

        var courseBody = {
          program: {
            PROGRAM_OF_STUDY_ID: this.selectedProgram.PROGRAM_OF_STUDY_ID,
            PROGRAM_STAGE_ID: this.selectedProgram.PROGRAM_STAGE_ID,
          },
          programPlanId: programPlanId,
          token: this.esisToken,
        };
        try {
          var response3 = await axios
            .post(
              "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/retrieveCoursesFromEsis",
              courseBody,
              headers
            )
            .then((response) => {
              return response.data;
            });
        } catch {
          console.log("pulling courses failed");
          this.pullingCourseLoading = false;
          this.$swal.fire(
            "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
          );
          return false;
        }
        console.log(response3);
        if (response3.status == false) {
          console.log("pulling courses failed, " + response3.reason);
          this.pullingCourseLoading = false;
          this.$swal.fire(
            "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
          );
          return false;
        }
        this.coursesData = {};
        await this.userData.school.ref
          .collection("schoolPrograms")
          .doc(String(PROGRAM_OF_STUDY_ID))
          .collection("stages")
          .doc(String(PROGRAM_STAGE_ID))
          .collection("plans")
          .doc(String(programPlanId))
          .get()
          .then((doc) => {
            if (doc.exists) {
              let programPlan = doc.data();
              programPlan.ref = doc.ref;
              programPlan.id = doc.id;
              this.coursesData.programPlan = programPlan;
            }
          });
        var counter = 0;
        this.coursesData.courses = [];
        response3.courses.forEach((course) => {
          counter++;
          course.index = counter;
          this.coursesData.courses.push(course);
        });
        this.pullingCourseLoading = false;
        this.$forceUpdate();
        this.showCoursesEsisDataDialog = true;
      }
    },
    async getLessonData(lesson) {
      if (!lesson.lessonSavingLoading) {
        lesson.lessonSavingLoading = true;
        this.$forceUpdate();

        if (!this.esisToken) {
          lesson.lessonSavingLoading = false;
          this.$swal.fire(
            "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
          );
          this.$forceUpdate();
          return false;
        }

        const headers = {
          "Content-Type": "application/json",
        };

        var studentBody = {
          program: {
            STUDENT_GROUP_ID: this.selectedProgram.STUDENT_GROUP_ID,
          },
          token: this.esisToken,
        };

        try {
          var response = await axios
            .post(
              "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/retrieveStudentsFromEsis",
              studentBody,
              headers
            )
            .then((res) => {
              return res.data;
            });
        } catch {
          console.error("pulling students failed");
          lesson.lessonSavingLoading = false;
          this.$swal.fire(
            "ESIS алдаа гарлаа. Та хүлээж байгаад дахин оролдоно уу!"
          );
          this.$forceUpdate();
          return false;
        }
        var programPlanId = null;
        var PROGRAM_STAGE_ID = null;
        var PROGRAM_OF_STUDY_ID = null;
        if (response.status == true) {
          var notEqualProgramPlanId = false;
          response.students.forEach((student) => {
            PROGRAM_STAGE_ID = student.PROGRAM_STAGE_ID;
            PROGRAM_OF_STUDY_ID = student.PROGRAM_OF_STUDY_ID;
            if (!programPlanId) {
              programPlanId = student.PROGRAM_PLAN_ID;
            } else {
              if (student.PROGRAM_PLAN_ID != programPlanId) {
                notEqualProgramPlanId = true;
              }
            }
          });

          if (notEqualProgramPlanId) {
            console.error("program plan id not same for all students");
            lesson.lessonSavingLoading = false;
            this.$swal.fire("ESIS алдаа гарлаа. Сурагчдын хөтөлбөр зөрсөн!");
            this.$forceUpdate();

            return false;
          }
          if (!programPlanId) {
            console.log("program plan id missing");
            lesson.lessonSavingLoading = false;
            this.$swal.fire("ESIS алдаа гарлаа. Сурагчдын хөтөлбөр дутуу!");
            this.$forceUpdate();

            return false;
          }
        }

        lesson.savedToEsisData.PROGRAM_STAGE_ID = PROGRAM_STAGE_ID;
        lesson.savedToEsisData.PROGRAM_PLAN_ID = programPlanId;
        lesson.savedToEsisData.PROGRAM_OF_STUDY_ID = PROGRAM_OF_STUDY_ID;
        await this.userData.school.ref
          .collection("schoolPrograms")
          .doc(String(PROGRAM_OF_STUDY_ID))
          .collection("stages")
          .doc(String(PROGRAM_STAGE_ID))
          .collection("plans")
          .doc(String(programPlanId))
          .get()
          .then((doc) => {
            if (doc.exists) {
              lesson.savedToEsisData.programPlan = doc.data();
            }
          });
        lesson.lessonSavingLoading = false;
        this.$forceUpdate();
        this.selectedEsisLesson = lesson;
        this.showLessonEsisDataDialog = true;
      }
    },
    isThisSemesterLesson(lesson) {
      if (lesson) {
        if (this.selectedHalfYear.yearId > 1) {
          if (
            (lesson.startEnd["semester-2"] &&
              lesson.startEnd["semester-2"].available) ||
            (lesson.startEnd["semester-3"] &&
              lesson.startEnd["semester-3"].available)
          ) {
            lesson.isNotThisSemester = false;
          } else {
            lesson.isNotThisSemester = true;
          }
        } else {
          if (
            lesson.startEnd["semester-1"] &&
            lesson.startEnd["semester-1"].available
          ) {
            lesson.isNotThisSemester = false;
          } else {
            lesson.isNotThisSemester = true;
          }
        }
      }
    },
    readLessons() {
      this.school.ref
        .collection("lessons-" + this.school.currentYear)
        .where("classGroupRefs", "array-contains", this.selectedProgram.ref)
        .orderBy("courseInfo.COURSE_NAME", "asc")
        .onSnapshot((docs) => {
          this.lessons = [];
          // var electiveLessonNumber = 0;
          // var mandatoryLessonNumber = 0;
          docs.forEach(async (doc) => {
            let lesson = doc.data();
            lesson.ref = doc.ref;
            lesson.id = doc.id;

            // console.log(this.selectedProgram.students);
            //2511
            if (
              lesson[
                "year" +
                  this.selectedHalfYearId +
                  "UnelgeeScores-" +
                  this.selectedProgram.id
              ] &&
              lesson[
                "year" +
                  this.selectedHalfYearId +
                  "UnelgeeScores-" +
                  this.selectedProgram.id
              ].numberStudentsOfUnelgee > this.selectedProgram.students.length
            ) {
              var tmp = {};
              tmp[
                "year" +
                  this.selectedHalfYearId +
                  "UnelgeeScores-" +
                  this.selectedProgram.id
              ] =
                lesson[
                  "year" +
                    this.selectedHalfYearId +
                    "UnelgeeScores-" +
                    this.selectedProgram.id
                ];
              tmp[
                "year" +
                  this.selectedHalfYearId +
                  "UnelgeeScores-" +
                  this.selectedProgram.id
              ].numberStudentsOfUnelgee = this.selectedProgram.students.length;
              lesson.ref.set(tmp, { merge: true });
            }

            if (!lesson.deleted) {
              // if (lesson.courseInfo.ENROLLMENT_CATEGORY == "MANDATORY") {
              //   mandatoryLessonNumber++;
              // } else {
              //   electiveLessonNumber++;
              // }

              lesson.students = this.selectedProgram.students;

              this.isThisSemesterLesson(lesson);
              if (!lesson.isNotThisSemester) {
                this.lessons.push(lesson);
              }
            }
          });

          // if (!this.lessonNumberSetupped) {
          //   var tmp = {};
          //   tmp["year" + this.selectedHalfYearId + "UnelgeeScores"] = this
          //     .selectedProgram[
          //     "year" + this.selectedHalfYearId + "UnelgeeScores"
          //   ]
          //     ? this.selectedProgram[
          //         "year" + this.selectedHalfYearId + "UnelgeeScores"
          //       ]
          //     : {};
          //   tmp[
          //     "year" + this.selectedHalfYearId + "UnelgeeScores"
          //   ].mandatoryLessonNumber = mandatoryLessonNumber;

          //   tmp[
          //     "year" + this.selectedHalfYearId + "UnelgeeScores"
          //   ].electiveLessonNumber = electiveLessonNumber;

          //   this.selectedProgram.ref.update(tmp);
          //   this.lessonNumberSetupped = true;
          // }

          this.lessons = this.lessons
            .sort(
              (a, b) =>
                a.courseInfo.SUBJECT_AREA_ID - b.courseInfo.SUBJECT_AREA_ID
            )
            .sort(
              (a, b) =>
                b.courseInfo.ENROLLMENT_CATEGORY.length -
                a.courseInfo.ENROLLMENT_CATEGORY.length
            );
          this.loading = false;

          this.refreshClassStatistics();

          if (this.setupped == false) this.createEeljitLessonsToEsis();
          if (!this.setupped) this.setupped = true;
        });
    },
    refreshClassStatistics() {
      var tmp = {};
      tmp["year" + this.selectedHalfYear.yearId + "UnelgeeScores"] = {
        mandatoryLessonNumber: 0,
        electiveLessonNumber: 0,
      };

      this.lessons.forEach((lesson) => {
        if (
          lesson[
            "year" +
              this.selectedHalfYear.yearId +
              "UnelgeeScores-" +
              this.selectedProgram.id
          ]
        ) {
          tmp["year" + this.selectedHalfYear.yearId + "UnelgeeScores"][
            lesson.id
          ] =
            lesson[
              "year" +
                this.selectedHalfYear.yearId +
                "UnelgeeScores-" +
                this.selectedProgram.id
            ];
        }
        if (lesson.courseInfo.ENROLLMENT_CATEGORY == "MANDATORY") {
          tmp["year" + this.selectedHalfYearId + "UnelgeeScores"]
            .mandatoryLessonNumber++;
        } else {
          tmp["year" + this.selectedHalfYearId + "UnelgeeScores"]
            .electiveLessonNumber++;
        }
      });

      console.log(tmp);
      this.selectedProgram.ref.update(tmp);
    },
    _print(item) {
      console.log(item, item.ref.path);
      console.log(this.currentSelectedLesson.length);
    },
    async forceRerender() {
      this.renderComponent = false;
      await this.$nextTick();
      this.renderComponent = true;
    },
  },
  filters: {
    formatDate(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        return moment(date).format("YYYY-MM-DD");
      }
      return "-";
    },
    formatDate2(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        try {
          var date = val.toDate();
        } catch {
          date = val;
        }

        return moment(date).fromNow();
      }
      return "-";
    },
  },
};
</script>
<style>
.scoreTables td {
  border: 1px solid #dddddd;
  height: 15px !important;
  padding: 0 !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.scoreTables th {
  border: 1px solid #dddddd;
  height: 15px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.vselectRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.swal2-actions-centered {
  justify-content: center !important;
}
.swal2-cancel-button-custom {
  background-color: #ececec !important; /* Change to desired color */
  color: black !important; /* Change text color if needed */
}

.swal2-select-bordered {
  border: 1px solid #ccc; /* Adjust border color and style as needed */
  border-radius: 5px; /* Adjust border radius as needed */
  padding: 5px; /* Adjust padding as needed */
}
.placeholder-option {
  color: red !important; /* Change to desired color */
}
.swal2-ok-button-custom {
  background-color: green !important; /* Change to desired color */
}
</style>
